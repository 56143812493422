@import './Color-settings.scss';
@import './Icons.scss';
@import "./Breakpoints.scss";


.btn-pd-primary{
    background: $color-pd-primary;
    border: 0;
    border-radius: 22px;
    color: white;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.3s ease-in-out;

    img{
        height: 19px;
        position: relative;
        top: -1px;
        width: 19px;
        transition: all 0.3s ease-in-out;
    }

    &:hover{
        background: $color-pd-primary-hover;
        color: white;
        transform: scale(0.95, 0.95);
        transition: all 0.3s ease-in-out;

        img{
            transform: scale(0.95, 0.95);
            top: -1px;
            transition: all 0.3s ease-in-out;
        }
        
    }

    &:disabled{
        background-image: url(../assets/img/svg/Preloader.svg);
        background-color: $color-pd-primary;
        opacity: 0.5;
        transform: none;
    }
}

.btn-pd-primary-border{
    color: $color-pd-primary;
    background-color: white;
    border: 1px solid $color-pd-primary;
    border-radius: 22px;
    padding: 8px 16px;
    transition: all 0.5s ease-in-out;

    span{
        color: $color-pd-primary;
        font-size: 16px;
        line-height: 24px;
        transition: all 0.5s ease-in-out;
    }

    i{
        background-image: $icon-chevron-right;
    }

    &:hover{
        background-color: $color-pd-primary;

        span{
            color: white;
            transition: all 0.5s ease-in-out;
        }

        i{
            background-image: $icon-chevron-right-white;
        }
    }

    &:disabled{
        opacity: 0.5;
    }

    @include media(">screen-sm-767", "<=screen-md-991"){
        padding: 4px 16px !important;

        span{
            font-size: 12px;
        }

        i{
            height: 12px;
            width: 12px;
            position: relative;
            top: 0.5px;
        }
    }
}

.btn-close{
    background-image: url(/src/assets/img/svg/off_outline_close.svg);
    background-size: 24px;
    opacity: 1;
    transform: scale(1, 1);
    transition: all 0.3s ease-in-out;

    &:hover{
        transform: scale(0.90, 0.90);
        transition: all 0.3s ease-in-out;
    }
}

.btn-pd-secondary{
    background-color: $color-pd-text-base;
    border: 1px solid $color-pd-text-base;
    border-radius: 100px;
    color: white;
    font-size: 24px;
    transform: scale(1, 1);
    padding: 7px 24px 8px;
    transition: all 0.3s ease-in-out;

    &:hover{
        color: white;
        transform: scale(0.98, 0.98);
        transition: all 0.3s ease-in-out;
    }

    @include media("<=screen-md-991"){
        font-size: 22px;
    }
}

.btn-pd-rounded{
    box-shadow: 0px 0px 16px rgba(33, 80, 59, 0.7);
    border-radius: 100px;
    border: 2px solid $color-pd-primary;
    transition: all 0.3s ease-in-out;

    &.primary{
        background-color: $color-pd-primary;
    }

    &.outline{
        background-color: white;
    }

    &:hover{
        transform: scale(0.90, 0.90) rotate(20deg);
        transition: all 0.3s ease-in-out;
    }
}