@import "../Styles/Color-settings.scss";
@import "../Styles/Icons.scss";
@import './Breakpoints.scss';

.form-group{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.5em;
    width: 100%;

    &__label{
        background-color: white;
        border-radius: 10px;
        color: $color-pd-text-base;
        position: relative;
        top: 10px;
        left: 12px;
        padding: 0 4px;
        z-index: 1;

        .mandatory{
            color: $color-pd-error;
        }
    }

    &__input{
        width: 100%;

        &.calendar{
            border-radius: 10px 0 0 10px;
            border-right-color: transparent;
            caret-color: transparent;
            cursor: default;
        }

        &.treatments .treatments-select{

            &__control{
                border-color: $color-pd-primary;
                border-radius: 10px;
                box-shadow: none;
                height: 46px;

                &--menu-is-open .treatments-select__indicator{
                    transform: rotate(180deg);
                }
            }

            &__value-container{
                padding: 3px 0 2px 14px;
            }

            &__single-value{
                color: $color-pd-text-base;
                font-family: Poppins-Regular;
                font-size: 16px;
            }

            &__indicator{
                background-image: $icon-chevron-down;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                height: 16px;
                width: 10px;
                padding: 0 52px 0 0;
                position: relative;
                top: 1px;

                svg{
                    display: none;
                }
            }

            &__indicator-separator{
                display: none;
            }

            &__menu{
                background-color: $color-pd-primary;
                border: 1px solid $color-pd-primary;
                border-radius: 10px 0 0 10px;
            }

            &__menu-list{
                border-radius: 10px;
                max-height: 200px;
                padding: 0;

                &::-webkit-scrollbar-track {
                    background-color: $color-pd-primary;
                    transition: background-color 0.5s ease, border-radius 0.5s ease;
                  }
                  &::-webkit-scrollbar {
                    width: 6px;
                    background-color: $color-pd-primary;
                    transition: background-color 0.5s ease, width 0.5s ease;
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: $color-pd-primary;
                    transition: background-color 0.5s ease, border-radius 0.5s ease;
                  }
                  &::-webkit-scrollbar-track-piece {
                    background-color: white;
                    transition: background-color 0.5s ease;
                  }
            }

            &__option{
                padding: 8px 15px;
                background-color: white;

                &--is-focused, &--is-selected{
                    background-color: $color-pd-primary;
                    color: white;
                }
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus{
            transition: background-color 600000s 0s, color 600000s 0s;
        }
    }

    .comment{
        border: 1px solid $color-pd-primary;
        border-radius: 10px;
        padding: 0 3px 0 0;
        width: 100%;
    }

    &__legal{

        .legal__container{
            align-items: center;
            display: flex;
            flex-direction: row;
            
            input{
                height: 18px;
                border: 2px solid $color-pd-primary;
                border-radius: 2px;
                padding: 0;
                display: grid;
                place-content: center;

                &::before{
                    content: "";
                    width: 0.65em;
                    height: 0.65em;
                    transform: scale(0);
                    background-color: $color-pd-primary;
                    transition: 120ms transform ease-in-out;
                    transform-origin: bottom left;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                }

                &:checked::before{
                    transform: scale(1);
                }
            }

            p{
                color: $color-pd-text-base;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;

                a{
                    color: $color-pd-primary;
                    transition: color 0.3s ease-in-out;

                    &:hover, &:focus{
                        color: $color-pd-primary-hover;
                        transition: color 0.3s ease-in-out;
                    }
                }
            }
        }
    }

    &__error{
        color: $color-pd-error;
        font-size: 14px;
        margin: 8px 0 -16px;
        padding-left: 16px;
        white-space: nowrap;
    }

    .input-group-text{
        background-color: white;
        border: 1px solid $color-pd-primary;
        border-left-color: transparent;
        border-radius: 0 10px 10px 0;
        width: 20%;
    }

    @include media("<=screen-sm-767"){

        &__label{
            font-size: 14px;
        }

        &__input{
            padding: 11px 16px 8px;
            font-size: 14px;
        }

        &__legal{

            .legal__container{
                gap: 0 15px;

                input{
                    height: 16px;
                }
                
                p{
                    font-size: min(max(12px, 2vw), 16px);
                    line-height: min(max(20px, 5vw), 22px);
                }
            }

        }

        &__error{
            font-size: min(max(12px, 2vw), 16px);
        }

        textarea{
            max-height: 100px !important;
        }
    }

}

.emailSent{
    padding: 1rem 1rem 2rem;
    opacity: 0;
    
    &__img{
        margin-bottom: 20px;
        width: 20%;
    }

    h3{
        text-align: center;

        &:first-of-type{
            color: $color-pd-primary;
        }

        &:last-of-type{
            color: $color-pd-primary-dark;
            font-size: 24px;
            line-height: 38px;
        }
    }

    @include media("<=screen-sm-767"){

        h3{
    
            &:last-of-type{
                font-size: min(max(16px, 3vw), 18px);
                line-height: min(max(24px, 5vw), 30px);
            }
        }
    }
}