$color-pd-primary : #05B667;
$color-pd-primary-opacity : rgba(5, 182, 102, 0.575);
$color-pd-primary-light: #dffff1;
$color-pd-primary-dark : #2E5B47;
$color-pd-primary-hover : #008F4F;
$color-pd-error: #fe6565;

$color-pd-text-base: #01120A;

$color-pd-empty-star: #cecece;
$color-pd-filled-star: $color-pd-primary;