@import './Color-settings.scss';
@import './Icons.scss';
@import './Breakpoints.scss';


#ContactModal{

    h5{
        color: $color-pd-primary;
        font-family: Poppins-Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
    }

    h3{
        color: $color-pd-primary-dark;
        font-family: Poppins-Bold;
        font-size: 20px;
        line-height: 30px;
    }

    #contactForm{
        margin-top: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 360px;
    
        .modal-body{
            margin-top: -20px;
            padding-top: 0;
        }
        
        .form-group{
    
            &__legal{
                margin-top: 16px;
    
                .legal__container{
                    gap: 0 20px;
                    
                    input{
                        width: 32px;
                    }
                }
            }
        }
    
        .modal-footer{
            border-top: 0;
            padding-bottom: 36px;
        }
    }

    .summaryCard{
        background-color: #008f4f34;
        border-radius: 8px;
        margin-top: 15px;
        padding: 30px;

        &__content{
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;
            justify-content: space-between;

            img{
                border-radius: 8px;
                height: 100%;
                max-height: 100px;
            }
            
            &-customerData{
    
                &--price{
                    color: $color-pd-primary;
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 45px;
                    text-align: center;

                    span{
                        font-size: 16px;
                        font-weight: normal;
                    }
    
                }
            }

            &-info{
                font-size: 14px;
            }
        }

    }

    .creditCard{
        background-color: #008f4f34;
        border-radius: 8px;
        margin-top: 15px;
        padding: 25px 30px;

        &__form{
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px 0;

            .cardElement{
                width: 100%;
                background-color: white;
                border: 1px solid $color-pd-primary;
                border-radius: 10px;
                font-family: Poppins-Regular;
                font-size: 16px;
                padding: 12px 16px;
            }

            &-buyBtn{
                padding: 6px 16px 8px;
            }
        }
    }

    @include media("<=screen-sm-767"){

        .modal-dialog-centered{
            margin: 10px 0 0;
        }

        .modal-content{
            width: 90%;
            max-width: 400px;
        }

        #contactForm{
            width: 100%;
            max-width: 350px;

            .modal-body{
                padding: 0 1.5rem 1rem;
                margin-top: -30px !important;
                width: 100%;
            }

            .form-group{

                &__legal{
                    padding: 0 0.5rem !important;
                }
            }

            .modal-footer{
                padding: 10px 0 20px;
                margin-top: -5px;

                button{
                    padding: 6px 30px 8px;
                }
            }
        }
    }
}
