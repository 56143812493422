
.icon{
    display: block;
    background-repeat: no-repeat;
}

$icon-chevron-right : url(/src/assets/img/svg/chevron_big_right.svg);
$icon-chevron-right-white : url(/src/assets/img/svg/chevron_big_right_white.svg);
$icon-chevron-right-black : url(/src/assets/img/svg/chevron_big_right_black.svg);
.icon-chevron-right{
    background-image: $icon-chevron-right;
    background-size: contain;
    height: 16px;
    width: 10px;
}

$icon-chevron-down: url(/src/assets/img/svg/chevron_big_down.svg);
.icon-chevron-down, .icon-chevron-up{
    background-image: $icon-chevron-down;
    background-size: 16px;
    background-position: center;
}

.icon-chevron-up{
    transform: rotate(180deg);
}

$icon-calendar : url(/src/assets/img/svg/calendar.svg);
.icon-calendar{
    background-image: $icon-calendar;
    background-size: 20px;
    background-position: center;
}

$icon-phone : url(/src/assets/img/svg/green-phone.svg);
.icon-phone-green{
    background-image: $icon-phone;
    background-size: contain;
    background-position: center;
}

$icon-whatsapp : url(/src/assets/img/svg/whatsapp-icon.svg);
.icon-whatsapp{
    background-image: $icon-whatsapp;
    background-size: contain;
    background-position: center;
}

$icon-mail : url(/src/assets/img/svg/mail.svg);
.icon-mail{
    background-image: $icon-mail;
    background-size: contain;
    background-position: center;
}

$icon-home : url(/src/assets/img/svg/home_alt_outline.svg);
.icon-home{
    background-image: $icon-home;
    background-size: contain;
    background-position: center;
}

$icon-facebook : url(/src/assets/img/svg/facebook.svg);
.icon-facebook{
    background-image: $icon-facebook;
    background-size: contain;
    background-position: center;
}

$icon-twitter : url(/src/assets/img/svg/twitter.svg);
.icon-twitter{
    background-image: $icon-twitter;
    background-size: contain;
    background-position: center;
}

$icon-instagram : url(/src/assets/img/svg/instagram.svg);
.icon-instagram{
    background-image: $icon-instagram;
    background-size: contain;
    background-position: center;
}

$icon-youtube : url(/src/assets/img/svg/youtube.svg);
.icon-youtube{
    background-image: $icon-youtube;
    background-size: contain;
    background-position: center;
}

$icon-chat : url(/src/assets/img/svg/chat-icon.svg);
.icon-chat{
    background-image: $icon-chat;
    background-size: contain;
    background-position: center;
}

$icon-green-circle : url(/src/assets/img/svg/green-circle.svg);
.icon-green-circle{
    background-image: $icon-green-circle;
    background-size: contain;
    background-position: center;
}

$icon-info : url(/src/assets/img/svg/info-icon.svg);
.icon-info{
    background-image: $icon-info;
    background-size: 22px;
    background-position: center;
}



/*#region TreatmentsIcons*/
$icon-odontology : url(/src/assets/img/svg/iconos-tratamientos/odontología-general-icon.webp);
.icon-odontology{
    background-image: $icon-odontology;
    background-size: contain;
    background-position: center;
}
$icon-maxillofacial-surgery : url(/src/assets/img/svg/iconos-tratamientos/cirugia-oral-icon.svg);
.icon-maxillofacial-surgery{
    background-image: $icon-maxillofacial-surgery;
    background-size: contain;
    background-position: center;
}
$icon-dental-hygiene : url(/src/assets/img/svg/iconos-tratamientos/higiene-dental-icon.webp);
.icon-dental-hygiene{
    background-image: $icon-dental-hygiene;
    background-size: contain;
    background-position: center;
}
$icon-conscious-sedation : url(/src/assets/img/svg/iconos-tratamientos/sedación-consciente-icon.webp);
.icon-conscious-sedation{
    background-image: $icon-conscious-sedation;
    background-size: contain;
    background-position: center;
}
$icon-surgery-aesthetics : url(/src/assets/img/svg/iconos-tratamientos/cirugia-estetica-dental-icon.svg);
.icon-surgery-aesthetics{
    background-image: $icon-surgery-aesthetics;
    background-size: contain;
    background-position: center;
}
$icon-endodoncy : url(/src/assets/img/svg/iconos-tratamientos/endodoncia-icon.svg);
.icon-endodoncy{
    background-image: $icon-endodoncy;
    background-size: contain;
    background-position: center;
}
$icon-implantology : url(/src/assets/img/svg/iconos-tratamientos/implantologia-icon.svg);
.icon-implantology{
    background-image: $icon-implantology;
    background-size: contain;
    background-position: center;
}
$icon-geriatric-dentistry : url(/src/assets/img/svg/iconos-tratamientos/odontogeriatria-icon.svg);
.icon-geriatric-dentistry{
    background-image: $icon-geriatric-dentistry;
    background-size: contain;
    background-position: center;
}
$icon-pediatric-dentistry : url(/src/assets/img/svg/iconos-tratamientos/odontopediatria-icon.svg);
.icon-pediatric-dentistry{
    background-image: $icon-pediatric-dentistry;
    background-size: contain;
    background-position: center;
}
$icon-ortodoncy : url(/src/assets/img/svg/iconos-tratamientos/ortodoncia-icon.webp);
.icon-ortodoncy{
    background-image: $icon-ortodoncy;
    background-size: contain;
    background-position: center;
}
$icon-periodoncy : url(/src/assets/img/svg/iconos-tratamientos/periodoncia-icon.svg);
.icon-periodoncy{
    background-image: $icon-periodoncy;
    background-size: contain;
    background-position: center;
}
$icon-oral-prevention : url(/src/assets/img/svg/iconos-tratamientos/prevencion-bucodental-icon.svg);
.icon-oral-prevention{
    background-image: $icon-oral-prevention;
    background-size: contain;
    background-position: center;
}

/*#endregion TreatmentsIcons*/

/*#region TreatmentsCharacteristics*/
$icon-safety-characteristic : url(/src/assets/img/svg/iconos-tratamientos/caracteristicas-tratamientos/seguros-caracteristicas-tratamientos.svg);
.icon-safety-characteristic{
    background-image: $icon-safety-characteristic;
    background-size: contain;
    background-position: center;
}

$icon-advanced-characteristic : url(/src/assets/img/svg/iconos-tratamientos/caracteristicas-tratamientos/avanzados-caracteristicas-tratamientos.svg);
.icon-advanced-characteristic{
    background-image: $icon-advanced-characteristic;
    background-size: contain;
    background-position: center;
}
$icon-comfortable-characteristic : url(/src/assets/img/svg/iconos-tratamientos/caracteristicas-tratamientos/comodos-caracteristicas-tratamientos.svg);
.icon-comfortable-characteristic{
    background-image: $icon-comfortable-characteristic;
    background-size: contain;
    background-position: center;
}
$icon-fast-characteristic : url(/src/assets/img/svg/iconos-tratamientos/caracteristicas-tratamientos/rapidos-caracteristicas-tratamientos.svg);
.icon-fast-characteristic{
    background-image: $icon-fast-characteristic;
    background-size: contain;
    background-position: center;
}
$icon-aesthetic-characteristic : url(/src/assets/img/svg/iconos-tratamientos/caracteristicas-tratamientos/esteticos-caracteristicas-tratamientos.svg);
.icon-aesthetic-characteristic{
    background-image: $icon-aesthetic-characteristic;
    background-size: contain;
    background-position: center;
}
/*#endregion TreatmentsCharacteristics*/





