@import './Breakpoints.scss';

#preloaderContainer{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: white;

    img{
        width: 25%;
    }
    
    @include media("<=screen-sm-767"){
        width: 100%;

        img{
            height: 100dvh;
            max-width: 350px;
            width: 60%;
        }
    }

}