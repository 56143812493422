@import './Color-settings.scss';

.custom-tooltip{
    font-family: Poppins-Regular;
    font-size: 12px;
    font-weight: 500;
    opacity: 1 !important;

    .tooltip-arrow::before{
        border-bottom-color: $color-pd-primary-light !important;
    }

    .tooltip-inner{
        background-color: $color-pd-primary-light;
        border-radius: 8px;
        color: $color-pd-text-base;
        padding: 0.6rem 0.8rem;
    }
}