@import './Color-settings.scss';
@import './Icons.scss';
@import './Breakpoints.scss';

.modal-dialog-centered{
    align-items: center;
    display: flex !important;
    justify-content: center;
    margin: 30px 0 0;
    max-width: 100%;
    position: absolute;
    width: 100%;

    .modal-content{
        align-items: center;
        border-color: $color-pd-primary;
        border-radius: 20px;
        width: 25%;

        .modal-header{
            align-self: flex-end;
            border-bottom: 0;
            z-index: 2;
        }

    }

    @include media("<=screen-xxl-1699"){

        .modal-content{
            width: 80%;
            max-width: 525px;
        }
    }
}

.modal-backdrop{
    background-color: $color-pd-primary;
    opacity: 0.2 !important;
}