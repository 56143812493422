@import "~bootstrap/scss/bootstrap";
@import './Color-settings.scss';
@import './Animations.scss';

$prefix: $enable-negative-margins;

@font-face {
  font-family: Poppins-Bold;
  src: url(/src/assets/fonts/Poppins/Poppins-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Poppins-Regular;
  src: url(/src/assets/fonts/Poppins/Poppins-Regular.ttf);
  font-display: swap;
}

body {
  margin: 0;
  overflow-x: hidden;
  padding: 0 !important;
  position: inherit;
  scroll-behavior: smooth;
  scrollbar-color: $color-pd-primary white;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,h2,h3,h4,h5,h6{
    font-family: Poppins-Bold;
    font-weight: 700;
    margin-bottom: 0;
  }

  p{
    color: $color-pd-text-base;
  }

  p, a, span{
    font-family: Poppins-Regular;
  }

  a{
    text-decoration: none;
    cursor: pointer;
  }

  button{
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }

  button, input, textarea{
    outline: none;
  }

  input{
    background-color: white;
    border: 1px solid $color-pd-primary;
    border-radius: 10px;
    font-family: Poppins-Regular;
    font-size: 16px;
    padding: 11px 16px 9px;

    &::placeholder{
      color: $color-pd-text-base;
      opacity: 0.5;
    }
  }

  textarea{
    border-color: transparent;
    border-radius: 10px;
    margin-bottom: -6px;
    max-height: 180px !important;
    padding: 11px 16px 9px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: $color-pd-primary;
      transition: background-color 0.5s ease, border-radius 0.5s ease;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: $color-pd-primary;
      transition: background-color 0.5s ease, width 0.5s ease;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: $color-pd-primary;
      transition: background-color 0.5s ease, border-radius 0.5s ease;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: white;
      transition: background-color 0.5s ease;
    }
  }

  input[type="number"]{

    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      display: none;
    }
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
  }

  #root{
    overflow-x: hidden;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: $color-pd-primary;
    transition: background-color 0.5s ease, border-radius 0.5s ease;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $color-pd-primary;
    transition: background-color 0.5s ease, width 0.5s ease;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $color-pd-primary;
    transition: background-color 0.5s ease, border-radius 0.5s ease;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: white;
    transition: background-color 0.5s ease;
  }

  .fadeOut{
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  .fadeIn{
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
}
