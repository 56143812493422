@import './Breakpoints.scss';

#callToActionButtons{
    gap: 12px;
    position: fixed;
    right: 0.5vw;
    top: 45%;
    z-index: 1001;

    .primary, .outline{
        align-items: center;
        display: flex;
        justify-content: center;
        height: 60px;
        width: 60px;
    }

    i{
        height: 30px;
        width: 30px;
    }

    @include media("<=screen-xs-575"){
        right: 1.5vw;
        top: 42%;
        gap: 6px;


        .primary, .outline{
            height: 45px;
            width: 45px;
        }

        i{
            height: 22px;
            width: 22px;
        }
    }

    @include media(">screen-xs-575", "<=screen-sm-767"){
        right: 1.5vw;
        top: 42%;

        .primary, .outline{
            height: 50px;
            width: 50px;
        }

        i{
            height: 25px;
            width: 25px;
        }
    }
}