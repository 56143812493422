@import './Color-settings.scss';
@import './Breakpoints.scss';

.navbar{
    padding: 16px 40px 17px;
    height: 120px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(105, 250, 185, 0.15);

    &__phoneBtnContainer{

        a{
            border-radius: 100px;
            padding: 9px 24px 8px;
        }

        img{
            height: 30px !important;
            width: 30px !important;
            top: -5px !important;
        }

        span{
            font-size: 28px;
        }
    }

    .navbar-brand{

        img{
            width: 280px;
            height: 84px;
        }
    }

    .navbar-nav{
        padding-right: 2vw;

        > li{
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 34px;
            justify-content: center;

            a{
                font-weight: 400;
                font-size: 18px;
                color: $color-pd-text-base;
                padding: 0 0.5rem;
                transition: color 0.3s ease-in-out;
                -webkit-transition: color 0.3s ease-in-out;
                -moz-transition: color 0.3s ease-in-out;

                + .nav-item__underlined{
                    border-color: transparent;
                    border-bottom: 2px solid;
                    width: 0;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                }

                &.active, &:hover, &.show {
                    color: $color-pd-primary-hover;
                    transition: color 0.3s ease-in-out;
                    -webkit-transition: color 0.3s ease-in-out;
                    -moz-transition: color 0.3s ease-in-out;
                    
                    + .nav-item__underlined{
                        width: 25%;
                        border-bottom: 2px solid;
                        border-color: $color-pd-primary;
                        border-radius: 50px;
                        transition: border-color 0.3s ease-in-out, width 0.3s ease-in-out;
                        -webkit-transition: border-color 0.3s ease-in-out, width 0.3s ease-in-out;
                        -moz-transition: border-color 0.3s ease-in-out, width 0.3s ease-in-out;
                    }
                }

                &:after{
                    display: none;
                }
            }

            &:not(:last-of-type){
                margin-right: 24px;

            }

            &.dropdown{

                .dropdown-menu{

                    &.show{
                        background-color: $color-pd-primary-light;
                        border: 0;
                        border-top: 4px solid $color-pd-primary;
                        border-bottom: 4px solid $color-pd-primary;
                        border-radius: 6px;
                        left: 8px;
                        opacity: 1;
                        padding: 0;
                        top: 35px;
                        transition: opacity 0.4s ease-in-out;
                    }

                    > li{
                        
                        a{
                            color: $color-pd-text-base;
                            transition: all 0.2s ease;
                            padding: 9px 16px;

                            &:hover, &:focus{
                                color: white;
                                background-color: $color-pd-primary;
                                transition: all 0.2s ease;
                            }
                        }

                        &.active{
                            background-color: $color-pd-primary;

                            a{
                                color: white;
                            }
                        }
                    }

                }
            }
        }
    }

    @include media("<=screen-xs-575"){
        padding: 8px 6vw;

        .navbar-brand{
            margin: -4px auto 0 -2vw !important;

            img{
                width: 150px;
            }
        }
        
    }
    
    @include media(">screen-xs-575", "<=screen-sm-767"){
        padding: 8px 5vw;

        .navbar-brand{
            margin: -4px auto 0 0 !important;

            img{
                width: 180px;
            }
        }

    }

    @include media("<=screen-sm-767"){
        height: 80px;
        width: 100%;
        max-width: 100%;

        .container-fluid{
            justify-content: flex-start;
        }

        .navbar-brand{
            order: 1;

            img{
                height: auto;
            }
        }

        .navbar-nav{
            padding-right: 0;
        }

        &__phoneBtnContainer{
            order: 2;
            margin-right: 4vw;

            a{
                margin: 0 !important;
                padding: 3px 12px 4px;

                img{
                    height: 14px !important;
                    width: 14px !important;
                    top: 0 !important;
                }
    
                span{
                    font-size: 15px;
                    margin-left: 0.25rem !important;
                }

                &:hover{
                    transform: none;

                    img{
                        transform: none;
                        top: 0;
                    }
                }
            }
        }

        .navbar-toggler{
            border: none;
            color: transparent;
            order: 3;
            padding: 10px 0;

            .navbar-toggler-icon{
                background-image: url(../assets/img/svg/hamburger.svg);
                  background-size: 85%;
            }
        }

        .navbar-collapse{

            &.show{
                background: linear-gradient(0deg, rgba(5,182,103,1) 0%, rgba(255,255,255,1) 100%);
                height: 100vh;
                left: 0;
                margin-top: 0 !important;
                padding: 0;
                position: fixed;
                top: 80px;
                width: 100%;

                .navbar-nav{
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px 0;
                    height: 100vh;
                    margin: 0 !important;
                    padding: 100px 0 0 0 !important;
                    width: 100%;

                    li{
                        height: auto;
                        margin: 0 !important;

                        > a{
                            align-items: center;
                            color: $color-pd-text-base;
                            display: flex;
                            flex-direction: column;
                            font-size: 20px;
                            letter-spacing: 2px;
                            padding: 0 !important;

                            .nav-item__underlined{
                                width: 0;
                                margin-left: -35px;
                                transition: border-color 0.3s ease-in-out, width 0.3s ease-in-out;
                            }

                            &.active, &:hover, &.show {

                                + .nav-item__underlined, .nav-item__underlined{
                                    width: 30%;
                                    border-bottom: 2px solid;
                                    border-color: $color-pd-primary;
                                }
                                
                                .nav-item__underlined{
                                    width: 25%;
                                    transition: border-color 0.3s ease-in-out, width 0.3s ease-in-out;
                                }
                            }

                            + .nav-item__underlined, .nav-item__underlined{
                                border-bottom: 2px solid;
                                border-color: transparent;
                            }
                            
                        }
                    }
                }

                .dropdown{

                    .dropdown-toggle{
                        margin-left: 30px;

                        span{
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            
                            i{
                                background-size: 20px;
                                height: 18px;
                                margin-left: 15px;
                                position: relative;
                                top: 1px;
                                width: 18px;
                            }
                        }

                        &.show{
                            margin-left: 30px;
                        }

                    }

                    .dropdown-menu{
                        display: block;
                        background-color: transparent;
                        border: none;
                        height: 0;
                        margin-left: 5px;
                        overflow: hidden;
                        padding: 0;
                        transition: all 0.5s ease-in-out;

                        &.show{
                            background-color: transparent;
                            border: 0;
                            height: 390px;
                            margin-left: 5px;
                            padding: 15px 0 0 0;
                            transition: height 0.5s ease-in-out;
                        }
    
                        > li{
                            text-align: center;
                            border-radius: 20px;

                            &:not(:last-of-type){
                                margin-bottom: 8px !important;
                            }

                            a{
                                background-color: transparent;
                                border-radius: 20px;
                                color: $color-pd-text-base;
                                padding: 9px 16px;
                                font-size: 16px;
                                transition: all 0.2s ease;
    
                                &:hover, &:focus{
                                    color: white;
                                    background-color: $color-pd-primary;
                                    padding: 0 8px !important;
                                    transition: all 0.2s ease;
                                }
                            }
    
                            &.active{
                                background-color: $color-pd-primary;
    
                                a{
                                    color: white;
                                    padding: 2px 8px !important;
                                }
                            }
                        }
    
                    }
                }
            }

            &.fadeIn{
                animation-duration: 0.3s;
            }

            &.fadeOut{
                animation-duration: 1s;
            }
        }
    }
}
