@import './Color-settings.scss';
@import './Breakpoints.scss';

#main-container{
  padding-top: 120px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  @include media("<=screen-sm-767"){
    padding-top: 70px;
  }
}
