@import './Color-settings.scss';
@import './Breakpoints.scss';

.footer{
    margin-top: 100px;

    &__logo{
        width: 300px;
    }

    &__header{
        color: $color-pd-primary;
        font-family: Poppins-Regular;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }

    &__otherSections{
        display: flex;
        flex-direction: column;
        align-items: center;

        ul{

            > li{
                line-height: 24px;

                &::marker{
                    font-size: 14px;
                }

                a{
                    color: $color-pd-text-base;
                    font-size: 16px;
                    line-height: 24px;
                    transition: all 0.3s ease-in-out;

                    &:hover, &:focus{
                        color: $color-pd-primary;
                        transition: all 0.3s ease-in-out;
                    }
                }

                &:not(:first-of-type){
                    margin-top: 10px;
                }
            }
        }
    }

    &__contactInfo{

        ul{
            li{
                list-style: none;
                width: fit-content;

                &:not(:first-of-type){
                    margin-top: 7px;
                }

                a{
                    color: $color-pd-text-base;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;
                    transition: all 0.3s ease-in-out;

                    &:hover, &:focus{
                        color: $color-pd-primary;
                        transition: all 0.3s ease-in-out;
                    }

                    i{
                        height: 20px;
                        width: 20px;
                    }

                    span{
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    &__address{

        &-map, .leaflet-container{
            height: 135px;
            width: 260px;
        }

        &-map{

            > a{
                align-items: center;
                color: $color-pd-text-base;
                display: flex;
                justify-content: flex-start;
                gap: 16px;
                transition: all 0.3s ease-in-out;
                width: 100%;
                min-width: 310px;


                &:hover, &:focus{
                    color: $color-pd-primary;
                    transition: all 0.3s ease-in-out;
                }

                i{
                    height: 32px;
                    width: 32px;
                }

                span{
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }

        .leaflet-control-zoom{
            border-color: $color-pd-primary;
            border-width: 1px;
            
            &-in, &-out{
                background-color: white;
                
                &:hover{
                    background-color: white;
                }
    
                span{
                    color: $color-pd-primary;
                    font-weight: 400;
                }

            }

            &-in{
                border-bottom: 1px solid $color-pd-primary;
            }
        }
    }

    &__timeShift{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .week, .weekend{
            text-align: center;

            p{
                font-size: 18px;
                line-height: 26px;
                text-align: center;
            }
        }
    }

    &__socialMedia{
        display: flex;
        gap: 16px;

        a{
            transition: all 0.3s ease-in-out;
        }

        a:hover{
            transform: scale(0.90, 0.90);
            transition: all 0.3s ease-in-out;
        }

        .icon{
            width: 43px;
            height: 43px;
        }
    }

    &__collaborators{

        &-header{
            color: $color-pd-primary;
            font-family: Poppins-Regular;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
        }

        &-logos{

            img{
                aspect-ratio: 16 / 9;
            }

            .ziacom {
                height: 60px;
                width: 90%;
            }
    
            .invisalign {
                height: 60px;
                width: 90%;
            }
    
            .kavo {
                height: 60px;
                width: 65%;
            }
            
            .shape {
                height: 60px;
                width: 100%;
            }
    
            .martina {
                height: 60px;
                width: 70%;
            }
        }
    }

    &__author{

        span{
            color: $color-pd-primary;
            text-decoration: underline;
            transition: all 0.15s ease-in-out;

            &:hover{
                color: $color-pd-primary-hover;
                transition: all 0.15s ease-in-out;
            }
        }
    }

    &__appointmentButton{
        margin-left: 3rem;
    }
    
    @include media("<=screen-xs-575"){

        &__otherSections{

            ul{
                align-items: baseline;
                display: flex;
                justify-content: flex-start;
                gap: 10vw;
                padding: 0 8vw 0 3vw;
                margin: 5px 0 0.5rem 0.5rem !important;
                width: 100%;
            }
        }

        &__contactInfo{
            padding: 0 6vw !important;

            h6{
                margin: 15px 0 0 0 !important;
            }

            ul{
                align-items: baseline;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 0 30px;
                padding: 0 8vw 0 0;
            }
        }

        &__address{

            &-map{

                .leaflet-container{
                    height: 150px;
                }
            }
        }

        &__appointmentButton{
            padding: 0 !important;
            text-align: center !important;

            a{
                font-size: 16px;
            }
        }

        &__socialMedia{
            padding: 0 !important;
            justify-content: center;
        }

        &__cta{
            padding: 0 2vw 30px !important;
        }

        // &__collaborators{
        //     display: none;

        //     // &-logos{
        //     //     gap: 4vw 0;

        //     //     .shape {
        //     //         height: 30px;
        //     //         max-width: 160px;
        //     //         width: 90%;
        //     //         margin-left: -3vw;
        //     //     }
        
        //     //     .martina {
        //     //         height: 30px;
        //     //         max-width: 140px;
        //     //         width: 100%;
        //     //     }
        //     // }
        // }

        
    }

    @include media(">screen-xs-575", "<=screen-sm-767"){

        &__otherSections{

            ul{
                padding: 0 0 0 2vw;
                margin: 10px 0 0.5rem 0.5rem !important;

                li:not(:last-of-type){
                    margin-bottom: 13px;
                }
            }
        }

        &__contactInfo{
            padding: 0 !important;

            ul{
                padding: 0 8vw 0 1.5vw;
                margin-top: 6px !important;

                li a{

                    i{
                        height: 18px;
                        width: 18px;
                    }

                    span{
                        font-size: min(max(12px, 3vw), 16px);
                    }
                }

            }
        }

        &__address{

            &-map{

                .leaflet-container{
                    height: 200px;
                }

                > a{
                    justify-content: center;
                }
            }
        }

        &__appointmentButton{
            padding: 0 2vw 0 0 !important;
            text-align: end !important;
        }

        &__socialMedia{
            padding: 0 0 0 3vw !important;
            justify-content: flex-start;
        }

        &__cta{
            padding: 0 2vw 30px 12vw !important;
        }

        // &__collaborators{

        //     &-logos{
        //         gap: 3vh 0;

        //         .shape {
        //             height: 40px;
        //             max-width: 180px;
        //             width: 100%;
        //             margin-left: -3vw;
        //             margin-top: -10px;
        //         }
        
        //         .martina {
        //             height: 40px;
        //             max-width: 150px;
        //             width: 100%;
        //         }
        //     }
        // }
    }

    @include media("<=screen-sm-767"){
        margin-top: 0;

        &__otherSections{
            align-items: flex-start;
            padding: 0 0 0 6vw;
        }

        &__contactInfo{

            h6{
                padding: 0 !important;
            }

            ul li a{
                gap: 12px;
            }
        }

        &__address{
            padding: 0 6vw !important;
            margin: 15px 0 0 0 !important;

            &-map{
                height: 100%;
                width: 100%;

                .leaflet-container{
                    width: 100%;
                }

                > a{
                    margin-top: 15px !important;
                    gap: 12px;

                    i{
                        height: 26px;
                        width: 26px;
                    }
                }
            }
        }

        &__timeShift{
            align-items: baseline;
            flex-direction: row;
            justify-content: center;
            gap: 0 8vw;
            margin: 20px 0 0 0 !important;
            padding: 0 6vw !important;
        }

        &__cta{
            margin: 15px 0 0 8px !important;
        }

        &__appointmentButton{
            margin-left: 0;

            button{
                font-size: 18px;
                white-space: nowrap;
                padding: 6px 20px 8px;
            }
        }

        &__socialMedia{

            .icon{
                height: 35px;
                width: 35px;
            }
        }

        &__collaborators{
            display: none;
            // padding: 0 6vw !important;
            // margin-bottom: 1.5rem !important;

            // &-header{
            //     font-size: min(max(18px, 4vw), 24px);
            // }

            // &-logos{
            //     flex-wrap: wrap;
            //     margin: 1rem 0 0 -4vw !important;

            //     .ziacom {
            //         height: auto;
            //         max-width: 160px;
            //         width: 80%;
            //         position: relative;
            //         top: -3px;
            //     }
        
            //     .invisalign {
            //         height: auto;
            //         max-width: 180px;
            //         width: 90%;
            //     }
        
            //     .kavo {
            //         height: auto;
            //         max-width: 120px;
            //         width: 85%;
            //     }
            // }
        }

        &__author{
            margin-top: 0 !important;
            padding-top: 0 !important;

            p{
                margin: 0;
                font-size: 14px;
            }
        }
    }

    @include media(">screen-sm-767", "<=screen-md-991"){

        &__logo{
            width: 35%;
            max-width: 300px;
        }

        &__otherSections{
            align-items: flex-start;
            padding: 0 0 0 6vw;
            order: 2;

            ul{
                margin-top: 12px !important;
                margin-left: 0 !important;
                padding-left: 2.5vw !important;

                li a span{
                    font-size: min(max(12px, 3vw), 16px);
                }

                > li:not(:last-of-type){
                    margin-bottom: 12px;
                }
            }
        }

        &__contactInfo{
            padding-left: 0 !important;
            order: 1;

            h6{
                padding: 0 !important;
                margin: 0 !important;
            }

            ul{
                padding-left: 0 !important;

                li a{
                    gap: 12px;

                    span{
                        font-size: min(max(12px, 3vw), 16px);
                    }
                }

                > li:last-of-type a span{
                    text-overflow: ellipsis;
                    width: 80%;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        &__address{
            padding: 0 0 0 2vw !important;
            margin: 10px 0 0 0 !important;
            order: 3;

            &-map{
                height: 100%;
                width: 100%;

                .leaflet-container{
                    width: 100%;
                    height: 120px;
                }

                > a{
                    display: none;
                }
            }
        }

        &__timeShift{
            order: 4;
            flex-direction: row;
            justify-content: center;
            gap: 0 8vw;
            margin: 20px 0 0 0 !important;
            padding: 0 6vw !important;
        }

        &__cta{
            padding: 0 6vw !important;
            margin: 15px 0 0 0 !important;
        }

        &__appointmentButton{
            margin-left: 0;

            button{
                font-size: 18px;
                white-space: nowrap;
            }
        }

        &__socialMedia{

            .icon{
                height: 35px;
                width: 35px;
            }
        }

        &__collaborators{
            padding: 0 6vw !important;
            margin-bottom: 1.5rem !important;

            &-header{
                font-size: min(max(18px, 4vw), 24px);
            }

            &-logos{
                flex-wrap: wrap;
                margin: 1rem 0 0 -4vw !important;

                .ziacom {
                    height: auto;
                    max-width: 160px;
                    width: 80%;
                    position: relative;
                    top: -3px;
                }
        
                .invisalign {
                    height: auto;
                    max-width: 180px;
                    width: 90%;
                }
        
                .kavo {
                    height: auto;
                    max-width: 120px;
                    width: 85%;
                }
            }
        }

    }
}

