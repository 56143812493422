@import "./Color-settings.scss";
@import './Breakpoints.scss';

.cookieConsent{

    &__content{

        &-header{
            background-color: $color-pd-primary;
            border-radius: 18px 18px 0 0;
            padding: 1.2rem;
            width: 100%;

            h5{
                color: white;
                font-family: Poppins-Regular;
                font-size: 22px;
                font-weight: 500;
                line-height: 26px;
            }
        }

        &-body{
            margin: 0 !important;
            padding: 25px 30px !important;

            a{
                color: $color-pd-primary;
            }
        }

        &-footer{
            border-top: 2px solid $color-pd-primary-light !important;
            padding: 16px 20px !important;
            width: 100%;

            button:first-of-type{
                padding: 6px 16px 8px !important;

                &:hover{
                    background-color: white;
                    border-color: $color-pd-primary;
                    color: $color-pd-primary;
                }
            }

            button:last-of-type{
                padding: 7px 18px 9px;

                &:hover{
                    transform: none;
                }
            }
        }
    }

    @include media("<=screen-xxl-1699"){

        &__content{

            &-header{
                padding: 1rem;
    
                h5{
                    font-size: min(max(16px, 4vw), 22px);
                    line-height: min(max(20px, 5vw), 30px);
                }
            }

            &-body{
                padding: 15px 18px !important;
                
                p, a{
                    font-size: min(max(13px, 3vw), 16px);
                    line-height: min(max(24px, 6vw), 26px);
                }
            }

            &-footer{
                padding: 15px !important;

                button{
                    font-size: min(max(12px, 4vw), 16px);

                    &:first-of-type{
                        padding: 4px 16px 8px !important;
                    }

                    &:last-of-type{
                        padding: 5px 16px 9px !important;
                        font-size: min(max(12px, 4vw), 16px);
                    }
                }
            }
        }
    }
}